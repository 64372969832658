import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {Button, TextH5} from "../../variables/StyledComponents";

const ComponentContainer = styled.div`
    height: 100%;
`;
const TermsContainer = styled.div`
    text-align:center;
    padding: 2rem;
    margin-bottom: -1px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    ${props => props.isIOS && css`
        overflow: auto;
    `}
`;

const InfoText = styled(TextH5)`
    line-height: 1.3;
    white-space: pre-wrap;
    padding: 1rem
`;

class PopupComponent extends Component {
    constructor(props) {
        super(props);
    }

    closePopup = () => {
        this.props.setAppState({renderTerms: false});
    };

    componentDidMount(){
        if(this.props.appState.renderTerms !== (true || this.state.toggle)) {
            this.setState({toggle: this.props.appState.renderTerms});
        }
    }

    render() {
        return (
            <ComponentContainer>
                <TermsContainer viewport={this.props.appState.viewportHeightPx} isIOS={this.props.appState.isIOS}>
                  <InfoText>
                  Skulle du få förhinder är
                    det viktigt att du avbokar, för att inte bli debiterad för den outnyttjade tiden. Fordon upp till 3 500
                    kg totalvikt måste avbokas senast 2 timmar före bokad tid. För registreringsbesiktning måste om-
                    eller avbokning ske 24 timmar innan den bokade tiden. Frågor? Kontakta vår kundtjänst på 0771-441
                    441.
                    </InfoText>  
                    <Button
                        onClick={this.closePopup.bind(this)}
                    >
                    OK        
                    </Button>                    
                </TermsContainer>
            </ComponentContainer>
        );
    }
}
export default PopupComponent;
