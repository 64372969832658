export const StringVariables = {
    stage2: {
        dropdownLoaderMessage: 'Laddar...'
    },
    errorMessage: {
        errorRetriving: 'Något gick fel med att hämta din position'
    },
    infoMessages: {
        noProduct: ' går tyvärr inte att boka på webben. Ring kundtjänst på 0771-441 441 för att få hjälp.',
        releaseBanner: 'Välkommen till vår nya bokningssida! Arbetet pågår fortfarande, om det är något som inte ' +
            'fungerar som förväntat kan du klicka här för att komma till den gamla bokningssidan.',
        vehicleHasDiscount: booking => {
            const dayEnd = booking.discounts[0].dayEnd !== (1 | 2 | 21 | 22 | 31)
                ? `${booking.discounts[0].dayEnd}:e`
                : `${booking.discounts[0].dayEnd}:a`;
            return (
                `Fordon ${booking.registrationNumber} har ett erbjudandet som är giltigt mellan 
                ${booking.discounts[0].validFrom} och ${booking.discounts[0].validTo} och gäller den ${booking.discounts[0].dayStart}-${dayEnd} i månaden. 
                Ditt pris är ${booking.discounts[0].discountPrice - booking.discounts[0].prepaymentDiscount - booking.discounts[0].bookingDiscount} kr om du bokar och betalar din besiktning på nätet.`
            )
        },
        inquiryProduct: (product) => {
            return (
                `Produkten ${product.shortDescription} är endast förfrågningsbar. Vi bekräftar din förfrågan när den behandlats av Kundtjänst.`
            )
        }
    },
    warningMessages: {
        vehicleRestriction: 'Du kan max ha ett fordon och ett släp åt gången.',
        sameRegNr: 'Du kan inte lägga till samma fordon flera gånger.',
        allowPositioning: 'För att sortera stationer på närhet måste du tillåta positionering.',
        vehicleIsDeregistered: 'Fordonet är avregistrerat - besiktning kan inte bokas.',
        tsUnresponsive: 'Din bokning sker utan komplett fordonsinformation från Transportstyrelsen, ' +
            'om vald station inte kan hantera ditt fordon kommer du bli kontaktad av Dekra.',
        noHeight: (vehicleMain, vehicleSecondary) => {
            vehicleMain = vehicleMain !== '' && vehicleSecondary !== '' ? `${vehicleMain} & ` : vehicleMain;
            return (
                `Fordon ${vehicleMain}${vehicleSecondary} saknar angiven höjd. De flesta av våra stationer 
                klarar 2.4 m höga bilar. De stationer som inte klarar den höjden har en maxhöjd angiven nedan. 
                Glöm inte att räkna med eventuell takbox.`
            )
        },
        recommendedProduct: (vehicleMain) => {
            return (
                `Transportstyrelsens rekommenderade produkt för ${vehicleMain.registrationNumber} är Kontrollbesiktning.`
            )
        }
    }

};

export const URLLocation = () => {
    const URL = window.location.host;
    const PROTOCOL =  window.location.protocol;

    if (URL.endsWith(':3000')) {
        return PROTOCOL + "//" + URL.substring(0, URL.length - 5)
    } else {
        return  PROTOCOL + "//" + URL;
    }
};
