import React, { Component } from 'react';
import styled, { css, keyframes} from 'styled-components';
import {SVG} from "../../../svg/SVG.js"
import {CSSCommon} from "../../../variables/CSSVariables";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const hover         = CSSCommon.color.hover;
const yellow        = CSSCommon.color.yellow;
const tabletMin      = CSSCommon.mediaBreakpoints.tabletMin;

const slideIn = keyframes`
    from   { transform: scale(0.9, 0.9); }
    to     { transform: scale(1, 1); }
`;

const slideInAnim = css`${slideIn} .1s;`;

const slideOut = keyframes`
    from   { transform: scale(1.1, 1.1); }
    to     { transform: scale(1, 1); }
`;

const slideOutAnim = css`${slideOut} .3s;`;

const Product = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    width: calc(100% - 2rem);
    height: 3.3rem;
    padding: 0 1rem;
    border: solid 0.1rem ${grey};
    border-top: none;
    fill: transparent;
    cursor: pointer;
    animation: ${slideOutAnim};
    @media (${tabletMin}){
        height: 4.2rem;
        &:hover {
            background: ${hover};
            border-left: solid ${hover} 0.1rem;
            border-right: solid ${hover} 0.1rem;
            ${props => props.isLast && css`
                border-bottom: solid ${hover} 0.1rem;
            `}
        }
    }
    ${props => props.choosen && css`
        width: 100%;
        border-color: ${green};
        fill: ${yellow};
        animation: ${slideInAnim};
        @media (${tabletMin}){
            width: calc(100% - 0.05rem);
        }
    `}
`;
const ProductName = styled.h4`
    flex-grow: 2;
    font-size: ${CSSCommon.fontSize.h4};
    font-weight: ${CSSCommon.fontWeight.medium};
    color: ${black};
    cursor: pointer;
`;
const ProductPrice = styled.h4`
    display: flex;
    flex-shrink: 0;
    font-size: ${CSSCommon.fontSize.h4};
    font-weight: ${CSSCommon.fontWeight.heavy};
    white-space: nowrap;
    color: ${green};
    margin: 0 0.5rem;
    cursor: pointer;
`;
const ProductMargin = styled.div`
    margin-left: 0.2rem;
`;
const SVGProductCheckbox = styled.div`
    width: 1.9rem;
    height: 1.9rem;
    min-width: 1.9rem;
    border: solid 0.1rem ${green};
    ${props => props.choosen && css`
        height: 1.6rem;
        padding-top: 0.3rem;
        background-color: ${green};
    `}
`;

class Stage1Product extends Component{
    constructor(props) {
        super(props);
        this.state = {
            intermediate: false
        };
    };
    onClick = () => {
        this.props.onClick();
    };

    renderPrice = () => {
        const discount = this.props.vehicle.discounts ? this.props.vehicle.discounts[0] : false;
        const price = discount && (this.props.product.id === discount.productId)
            ? discount.discountPrice - discount.prepaymentDiscount - discount.bookingDiscount
            : this.props.product.lowestPrice;
        return <ProductPrice>fr. {price}<ProductMargin/>kr</ProductPrice>
    };
    shouldComponentUpdate(nextProps) {
        if (this.props.product !== nextProps.product) {
            return true;
        }
        return false;
    }
    render(){
        const product = this.props.product;
        const price = product.lowestPrice ? this.renderPrice() : null;
        return(
            <Product
                choosen={product.recommended}
                intermediate={this.state.intermediate}
                isLast={this.props.isLast}
                onClick={this.onClick}
            >
                <ProductName>{product.shortDescription}</ProductName>
                <SVGProductCheckbox choosen={product.recommended}>
                    {SVG.checkmark}
                </SVGProductCheckbox>
            </Product>
        )

    }
}

export default Stage1Product;
