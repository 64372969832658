import React from 'react';
import styled, {css} from 'styled-components';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";
import {TextH5} from "../../variables/StyledComponents";
import { sendGTMEvent } from '../../resource-functions/HelperFunctions'

import StageHeaderComponent     from "../StageHeaderComponent";

const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const hover         = CSSCommon.color.hover;
const yellow        = CSSCommon.color.yellow;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;
const mobileMax     = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 30rem;
    max-width: 32.4rem;
    margin-bottom: 2rem;
    ${props => props.isMobile && css`
        @media(${tabletMin}){
            display: none;
        }
    `}
    ${props => !props.isMobile && css`
        @media(${mobileMax}){
            display: none;
        }
    `}
`;
const SubsectionContainer = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(50% - 0.5rem);
    padding: 1rem;
    margin-bottom: 0.5rem;
    border: solid 0.1rem ${grey};
    ${props => props.choosen && css`
        border: solid 0.1rem ${green};
    `}
    ${props => !props.choosen && css`
        &:hover{
            border-color: ${hover}; 
            background-color: ${hover};
            cursor: pointer;
        }
    `}
`;
const InfoButton = styled.button`
    display: flex;
    &:hover {
        text-decoration: underline;
    }
`;
const SVGContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
const SVGKlarna = styled.svg`
    width: calc(100% - 4.1rem);
    height: 2rem;
    fill: ${black};
`;
const SVGGarage = styled.svg`
    width: calc(100% - 4.1rem);
    height: 3.6rem;
    fill: ${black};
`;
const SVGInfo = styled.svg`
    width: 1.6rem;
    height: 1.6rem;
    fill: ${black};
    cursor: pointer;
`;
const SVGCheckbox = styled.div`
    position: relative;
    top: -0.9rem;
    right: -0.9rem;
    width: 1.9rem;
    min-width: 1.9rem;
    height: 1.9rem;
    border: solid 0.1rem ${green};
    fill: transparent;
    ${props => props.choosen && css`
        height: 1.6rem;
        padding-top: 0.3rem;
        background-color: ${green};
        fill: ${yellow};
    `}
`;
const SVGPseudoCheckbox = styled.div`
    min-width: 2rem;
    height: 2rem;
`;
const H5 = styled(TextH5)`
    width: 100%;
    font-weight: ${CSSCommon.fontWeight.heavy};
    text-align: center;
    margin: 0.5rem 0;
    ${props => !props.choosen && css`
        cursor: pointer;
    `}
`;
const KlarnaInfo = styled(TextH5)`
    width: 100%;
    font-weight: ${CSSCommon.fontWeight.normal};
    text-align: left;
    margin-bottom: 1rem;
    ${props => !props.choosen && css`
        cursor: default;
    `}
`;
const LinkInfo = styled.p`
    flex-grow: 1;
    align-self: center;
    text-align: start;
    height: auto;
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.heavy};
    color: ${black};
    padding-left: 0.5rem;
    text-decoration: none;
    cursor: pointer;
`;
const H5Heavy = styled(TextH5)`
    font-weight: ${CSSCommon.fontWeight.heavy};
    color: ${green};
    padding-bottom: 0.5rem;
    ${props => props.klarna && css`
        //color: ${yellow};
        padding: 0.5rem;
        border-radius: 0.3rem;
        background: ${yellow};
    `}
    ${props => !props.choosen && css`
        cursor: pointer;
    `}
`;

const PaymentComponent = props => {
    const {appState, setAppState} = props;
    let bookingIncludeInquiry = appState.bookingIncludeInquiry;

    const choosen = appState.payWithKlarna

    const payWithKlarna = () => {
        props.setAppState({payWithKlarna: true});
        sendGTMEvent('stage-3','paymentSelect','payWithKlarna')
    };
    const payInShop = () => {
        props.setAppState({payWithKlarna: false});
        sendGTMEvent('stage-3','paymentSelect','payInShop')
    };
    const triggerTerms = () => {
        props.setAppState({renderTerms: true});
    };
    const extractPrice = klarna => {
        let totalPrice = 0;
        if(klarna){
            appState.booking.items.forEach((item) => {
                totalPrice += item.price;
                totalPrice -= item.discount;
            });
        } else {
            appState.booking.items.forEach((item) => {
                totalPrice += item.price;
                totalPrice -= item.bookingDiscount;
            });
        }
        return totalPrice
    };
    return (
        <ComponentContainer isMobile={props.isMobile}>
            <StageHeaderComponent headerNr={appState.stage3.header4} {...{appState, setAppState}}/>

            { bookingIncludeInquiry === false &&
                <SubsectionContainer onClick={payWithKlarna} choosen={choosen}>
                    <SVGContainer>
                        <SVGPseudoCheckbox/>
                        <SVGKlarna>
                            {SVG.klarnaLogo}
                        </SVGKlarna>
                        <SVGCheckbox choosen={choosen}>
                            {SVG.checkmark}
                        </SVGCheckbox>
                    </SVGContainer>
                    <H5 choosen={choosen}>Betala med kortbetalning, direktbank eller faktura via Klarna</H5>
                    <H5Heavy choosen={choosen} klarna>{extractPrice(true)}kr</H5Heavy>
                </SubsectionContainer>
            }

            <SubsectionContainer onClick={payInShop} choosen={!choosen}>
                <SVGContainer>
                    <SVGPseudoCheckbox/>
                    <SVGGarage>
                        {SVG.garage}
                    </SVGGarage>
                    <SVGCheckbox right choosen={!choosen}>
                        {SVG.checkmark}
                    </SVGCheckbox>
                </SVGContainer>
                <H5 choosen={!choosen}>Betala med kort på station.</H5>
                <H5Heavy choosen={!choosen}>{extractPrice()}kr</H5Heavy>
            </SubsectionContainer>
        </ComponentContainer>
    );
};
export default PaymentComponent






























