import React from 'react';
import styled, { css } from 'styled-components';
import {CSSCommon} from "./variables/CSSVariables.js"
// import {SVG} from "./svg/SVG.js"

import HeaderComponent                                  from './components/header-component/HeaderComponent'
import ButtonComponent                                  from "./components/ButtonComponent";
import Stage1Component                                  from "./components/stage1-component/Stage1Container";
import Stage2Component                                  from "./components/stage2-component/Stage2Container";
import Stage3Component                                  from "./components/stage3-component/Stage3Container";
import Stage4Component                                  from "./components/stage4-component/Stage4Container";
import Stage4ButtonComponent                            from "./components/stage4-component/Stage4ButtonComponent";
import LoaderComponent                                  from "./components/LoaderComponent";
import AlertMessageComponent                            from "./components/alert-message-components/AlertMessageComponent";
import PersistentAlertMessageComponent                  from "./components/alert-message-components/PersistentAlertMessageComponent";
import PopupRegNr                                       from './components/stage1-component/PopupRegistrationNr'
import MapComponent                                     from "./components/map-component/MapContainer";
import KlarnaComponent                                  from "./components/stage3-component/KlarnaComponent";
import TermsComponent                                   from "./components/stage3-component/TermsComponent";
import EditBookingComponent                             from "./components/stage1-component/EditBookingComponent";
import TsUnavailableComponent                           from "./components/stage1-component/ts-unavailable-component/TsUnavailableComponent";
import BookingHasChangedWarningComponent                from "./components/stage1-component/BookingHasChangedWarningComponent";
import PreliminaryBookingPopupComponent                 from "./components/PreliminaryBookingPopupComponent";
import PreliminaryBookingAllreadyTakenPopupComponent    from "./components/PreliminaryBookingAllreadyTakenPopupComponent";
import PopupComponent                                   from "./components/PopupComponent";
import FirstFreeAndCheapestTimeslotContainer            from "./components/stage2-component/timeslot-components/FirstFreeAndCheapestTimeslotContainer";
import CalendarContainer                                from "./components/stage2-component/calendar-component/CalendarContainer";
import InquiryPopupComponent                            from "./components/InquiryPopupComponent";

const tabletMin         = CSSCommon.mediaBreakpoints.tabletMin;
const desktopMin        = CSSCommon.mediaBreakpoints.desktopMin;
const desktopMinInt     = CSSCommon.mediaBreakpointsInt.desktopMin;

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem 0 10rem 0;
    background-color: white;
`;
const StageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 2rem);
    margin: 0 1rem;
    @media(${tabletMin}){
        width: calc(100% - 10rem);
        margin: 0 5rem;
    }
`;
const GreyoutBar = styled.div`
    @media(${desktopMin}){
        position: fixed;
        top: 0;
        left: 0;
        width: calc(50vw - (${desktopMinInt}px / 2));
        height: ${props => props.viewport}px;
        background: gainsboro;
        z-index: 55;
        ${props => props.right && css`
            left: auto;
            right: 0;
        `}
    }
`;

const App = (props) => {
    const {appState, setAppState} = props;
    const activeStageHeader = () => {
       if(appState.activeStage.stage1) {return 'VÄLJ FORDON & PRODUKT'}
       else if(appState.activeStage.stage2) {return 'VÄLJ BESIKTNINGSSTATION & TID'}
       else if(appState.activeStage.stage3) {return 'SUMMERING & BETALNING'}
       else if(appState.activeStage.stage4) {return 'BOKNINGSBEKRÄFTELSE'}
    };
    const renderPopupRegNr = () => {
        return <PopupRegNr {...{appState, setAppState}}/>
    };
    const renderTsUnavailable = () => {
        return (
            <PopupComponent
                thin
                green
                header="Fordon hittas ej"
                svg="dekra"
                type="renderTsUnavailable"
                tsUnavailable
                {...{appState, setAppState}}
            >
                <TsUnavailableComponent {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    const renderTermsComponent = () => {
        return (
            <PopupComponent
                terms
                green
                header="Bokningsregler"
                svg="dekra"
                type="renderTerms"
                {...{appState, setAppState}}
            >
                <TermsComponent {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    const renderKlarnaComponent = () => {
        return (
            <PopupComponent
                klarna
                header="Betalning med Klarna"
                svg="creditCard"
                type="renderKlarna"
                {...{appState, setAppState}}
            >
                <KlarnaComponent {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    const renderLoader = (appState, setAppState) => {
        return <LoaderComponent {...{appState, setAppState}}/>
    };
    const renderAlert = (alertType, isPersistent) => {
        return (
            <AlertMessageComponent
                alertType={alertType}
                persistent={isPersistent}
                {...{appState, setAppState}}
            />
        )
    };
    const renderPersistentAlert = (alertType, svg, releaseBanner) => {
        return (
            <PersistentAlertMessageComponent
                alertType={alertType}
                svg={svg}
                releaseBanner={releaseBanner}
                {...{appState, setAppState}}
            />
        )
    };
    const renderMapPopupComponent = (appState, setAppState) => {
        return (
            <MapComponent {...{appState, setAppState}}/>
        )
    };
    const renderEditBookingComponent = () => {
        const header = appState.renderResendBookingConfirmation ? 'Skicka bokningsbekräftelse' : 'Ändra och betala bokning';
        return (
            <PopupComponent
                thin
                green
                header={header}
                svg="dekra"
                type="renderEditBooking"
                {...{appState, setAppState}}
            >
                <EditBookingComponent {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    const renderFirstFreeTimeslotPopupComponent = (appState, setAppState) => {
        return (
            <PopupComponent
                header="Första lediga"
                svg="time"
                type="renderFirstFreeTimeslot"
                {...{appState, setAppState}}
            >
                <FirstFreeAndCheapestTimeslotContainer firstFree {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    const renderCheapestTimeslotPopupComponent = (appState, setAppState) => {
        return (
            <PopupComponent
                header="Billigast inom fyra veckor"
                svg="creditCard"
                type="renderCheapestTimeslot"
                {...{appState, setAppState}}
            >
                <FirstFreeAndCheapestTimeslotContainer cheapest {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    const renderCalendarPopupComponent = (appState, setAppState) => {
        return (
            <PopupComponent
                cal={!appState.toggleCalendar}
                header="Kalender"
                svg="calendar"
                type="renderCalendar"
                {...{appState, setAppState}}
            >
                <CalendarContainer {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    const renderBookingHasChangedWarningComponent = (appState, setAppState) => {
        return(
            <PopupComponent
                thin
                overflowAuto
                header="Ändra bokning"
                svg="danger"
                type="renderBookingHasChangedWarning"
                {...{appState, setAppState}}
            >
                <BookingHasChangedWarningComponent {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };
    const renderPreliminaryBookingPopupComponent = (appState, setAppState) => {
        return(
            <PopupComponent
                thin
                overflowAuto
                header="Bekräfta reserverad tid"
                svg="dekra"
                type="renderPreliminaryBookingPopup"
                {...{appState, setAppState}}
            >
                <PreliminaryBookingPopupComponent {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };

    const renderPreliminaryBookingAllreadyTakenPopupComponent = (appState, setAppState) => {
        return(
            <PopupComponent
                thin
                overflowAuto
                header="Föreslagen tid är upptagen"
                svg="dekra"
                type="renderPreliminaryBookingAllreadyTakenPopup"
                {...{appState, setAppState}}
            >
                <PreliminaryBookingAllreadyTakenPopupComponent {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };

    const renderInquiryPopupComponent = (appState, setAppState) => {
        return(
            <PopupComponent
                thin
                overflowAuto
                header="Bokningsförfrågan"
                svg="dekra"
                type="renderInquiryPopup"
                {...{appState, setAppState}}
            >
                <InquiryPopupComponent {...{appState, setAppState}}/>
            </PopupComponent>
        )
    };

    const persistentAlertWarning = appState.renderAlert.persistentWarning && appState.activeStage.stage3 === true
        ? renderPersistentAlert('persistentWarning', 'warning')
        : null;
    const alertInfo = appState.renderAlert.info ? renderAlert('info') : null;
    const alertWarning = appState.renderAlert.warning ? renderAlert('warning') : null;
    const alertError = appState.renderAlert.error ? renderAlert('error') : null;

    const stage1 = appState.activeStage.stage1 ? <Stage1Component {...{appState, setAppState}}/> : null;
    const stage2 = appState.activeStage.stage2 ? <Stage2Component {...{appState, setAppState}}/> : null;
    const stage3 = appState.activeStage.stage3 ? <Stage3Component {...{appState, setAppState}}/> : null;
    const stage4 = appState.activeStage.stage4 ? <Stage4Component {...{appState, setAppState}}/> : null;

    const buttonGroup = !appState.activeStage.stage4 ? <ButtonComponent {...{appState, setAppState}}/> : <Stage4ButtonComponent {...{appState, setAppState}}/>;

    const popupRegNr = appState.inputPopup.renderPopup ?  renderPopupRegNr() : null;
    const popupTsUnavailable = appState.renderTsUnavailable ?  renderTsUnavailable() : null;
    const loader = appState.renderLoader ?  renderLoader(appState, setAppState) : null;
    const mapPopupComponent = appState.renderMap ? renderMapPopupComponent(appState, setAppState) : null;
    const klarnaPopupComponent = appState.renderKlarna ? renderKlarnaComponent(appState, setAppState) : null;
    const termsPopupComponent = appState.renderTerms ? renderTermsComponent(appState, setAppState) : null;
    const editBookingPopupComponent = appState.renderEditBooking ? renderEditBookingComponent(appState, setAppState) : null;
    const bookingHasChangedWarningComponent = appState.renderBookingHasChangedWarning ? renderBookingHasChangedWarningComponent(appState, setAppState) : null;
    const preliminaryBookingPopupComponent = appState.renderPreliminaryBookingPopup ? renderPreliminaryBookingPopupComponent(appState, setAppState) : null;
    const inquiryPopupComponent = appState.renderInquiryPopup ? renderInquiryPopupComponent(appState, setAppState) : null;
    const preliminaryBookingAllreadyTakenPopupComponent = appState.renderPreliminaryBookingAllreadyTakenPopup ? renderPreliminaryBookingAllreadyTakenPopupComponent(appState, setAppState) : null;

    const firstFreeTimeslotPopupComponent = appState.renderFirstFreeTimeslot ? renderFirstFreeTimeslotPopupComponent(appState, setAppState) : null;
    const cheapestTimeslotPopupComponent = appState.renderCheapestTimeslot ? renderCheapestTimeslotPopupComponent(appState, setAppState) : null;
    const calendarPopupComponent = appState.renderCalendar ? renderCalendarPopupComponent(appState, setAppState) : null;

    return (
        <AppContainer>
            <GreyoutBar viewport={appState.viewportHeightPx}/>
            <GreyoutBar viewport={appState.viewportHeightPx} right/>
            <HeaderComponent header={activeStageHeader()} {...{appState, setAppState}}>
            </HeaderComponent>
            {persistentAlertWarning}
            {alertInfo}
            {alertWarning}
            {alertError}
            <StageContainer id="StageContainer">
                {stage1}
                {stage2}
                {stage3}
                {stage4}
            </StageContainer>
            {popupRegNr}
            {popupTsUnavailable}
            {loader}
            {buttonGroup}
            {mapPopupComponent}
            {klarnaPopupComponent}
            {termsPopupComponent}
            {editBookingPopupComponent}
            {bookingHasChangedWarningComponent}
            {preliminaryBookingPopupComponent}
            {preliminaryBookingAllreadyTakenPopupComponent}
            {firstFreeTimeslotPopupComponent}
            {cheapestTimeslotPopupComponent}
            {calendarPopupComponent}
            {inquiryPopupComponent}
        </AppContainer>
    );
};
export default App;
