import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import validator from 'email-validator';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables";
import {Input, Label, SVGLabel, TextH6} from "../../variables/StyledComponents";

const green         = CSSCommon.color.green;
const grey          = CSSCommon.color.grey;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 28.8rem;
    max-width: 31.2rem;
    min-height: 3.8rem;
    border: solid 0.1rem ${green};
    padding: 1rem 0.5rem;
    margin-bottom: 2rem;
    @media(${tabletMin}){
        padding: 1.5rem 1rem;
    }
`;

const InputContacts = styled(Input)`
    margin-bottom: 1rem;
`;
const LabelContacts = styled(Label)`
    margin-top: -0.5rem;
`;
const Textarea = styled.textarea`
    width: calc(100% - 1rem);
    min-height: 6.8rem;
    padding-top: 1rem;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    border: none;
    border-radius: 0.3rem;
    background: ${grey};
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.medium};
    resize: none;
`;

class ContactsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validateEmail: null,
            validatePhone: null
        };
    }
    alterInput = (e, type) => {
        this.contactInformation[type] = e;
        this.props.setAppState({
            contactInformation: this.contactInformation,
            contactsIsValid: this.validateContacts()
        });
        if((this.state.validateEmail !== null && this.state.validatePhone !== null)
            || this.props.appState.validateContacts)
        {
            this.validateInput(type)
        }
    };
    toggleTerms = type => {
        this.props.setAppState({renderTerms: type});
    };
    scrollIntoView = (nr) => {
        if(this.props.appState.viewportMobile){
            document.getElementsByTagName('form')[0][nr].scrollIntoView({behavior: "smooth", block: "center"});
        }
    };
    validateContacts = () => {
        return(
            validator.validate(this.contactInformation.email)
            && ((this.contactInformation.phoneNumber.length > 7)
                && !this.contactInformation.phoneNumber.match(/[a-z]/i))
        )
    };
    validateInput = type => {
        switch(type) {
            case 'email':
                this.setState({validateEmail: validator.validate(this.contactInformation.email)});
                break;
            case 'phoneNumber':
                this.setState({validatePhone: ((this.contactInformation.phoneNumber.length > 7) && !this.contactInformation.phoneNumber.match(/[a-z]/i))});
                break;
            default:
                break;
        }
    };
    emailIsValid = () => {
        if(this.state.validateEmail){
            return true
        } else if (this.props.appState.validateContacts) {
            return false
        } else if (this.state.validateEmail === null){
            return true
        }
    };
    phoneIsValid = () => {
        if(this.state.validatePhone){
            return true
        } else if (this.props.appState.validateContacts) {
            return false
        } else if (this.state.validatePhone === null){
            return true
        }
    };
    
    componentWillMount() {
        this.contactInformation = JSON.parse(JSON.stringify(this.props.appState.contactInformation));
        if(!this.contactInformation) {
            this.contactInformation = {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                comment: '',
                commentRead: false,
                sendConfirmationEmail: false,
                allowInformationMailings: false
            };
        } else {
            this.props.setAppState({
                contactInformation: this.contactInformation
            });
        }
    }
    componentDidMount() {
        if(this.props.appState.contactInformation.email
            && (this.props.appState.contactInformation.email !== ''
                || this.props.appState.contactInformation.phoneNumber !== '')
        ){
            this.validateInput('email');
            this.validateInput('phoneNumber');
            if(this.validateContacts){
                this.props.setAppState({contactsIsValid: true});
            }
        }
    }
    render() {
        const contacts = this.contactInformation;
        return (
            <ComponentContainer>
                <form>
                    <InputContacts
                        type="text"
                        value={contacts.firstName}
                        placeholder="Förnamn"
                        maxLength="24"
                        isValid
                        onChange={e => this.alterInput(e.target.value, 'firstName')}
                        onFocus={this.scrollIntoView.bind(this, 0)}
                    />
                    <InputContacts
                        type="text"
                        value={contacts.lastName}
                        placeholder="Efternamn"
                        maxLength="24"
                        isValid
                        onChange={e => this.alterInput(e.target.value, 'lastName')}
                        onFocus={this.scrollIntoView.bind(this, 1)}
                    />
                    <LabelContacts isValid={this.emailIsValid()}><SVGLabel>{SVG.danger}</SVGLabel>Fyll i korrekt e-post:</LabelContacts>
                    <InputContacts
                        type="email"
                        value={contacts.email}
                        placeholder="E-post *"
                        maxLength="70"
                        isValid={this.emailIsValid()}
                        onChange={e => this.alterInput(e.target.value.trim(), 'email')}
                        onFocus={this.scrollIntoView.bind(this, 2)}
                        onBlur={this.validateInput.bind(this, 'email')}
                    />
                    <LabelContacts isValid={this.phoneIsValid()}><SVGLabel>{SVG.danger}</SVGLabel>Fyll i korrekt telefonnummer:</LabelContacts>
                    <InputContacts
                        type="tel"
                        value={contacts.phoneNumber}
                        placeholder="Telefonnummer *"
                        maxLength="12"
                        isValid={this.phoneIsValid()}
                        onChange={e => this.alterInput(e.target.value.trim(), 'phoneNumber')}
                        onFocus={this.scrollIntoView.bind(this, 3)}
                        onBlur={this.validateInput.bind(this, 'phoneNumber')}
                    />
                    <Textarea
                        type="text"
                        value={contacts.comment}
                        placeholder="Övrigt"
                        maxLength="256"
                        onChange={e => this.alterInput(e.target.value, 'comment')}
                        onFocus={this.scrollIntoView.bind(this, 4)}
                    />
                    <TextH6>Fälten markerade med * är obligatoriska.</TextH6>
                </form>
            </ComponentContainer>
        );
    }
}
export default ContactsComponent






























