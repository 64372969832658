import React from 'react';
import styled, {css} from 'styled-components';
//Styled components
import {Button} from "../variables/StyledComponents";
import {CSSCommon} from "../variables/CSSVariables";

const black         = CSSCommon.color.black;
const white         = CSSCommon.color.white;
const margin        = CSSCommon.margin.normal;
const maxRowWidth   = CSSCommon.maxRowWidth;
const tabletMin   = CSSCommon.mediaBreakpoints.tabletMin;

const ButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 1rem;
    background: ${white};
    box-shadow: 0 -1rem 1rem ${white};
    z-index: 2;
`;
const ButtonColumn = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 70.8rem;
    @media(${tabletMin}){
        justify-content: space-between;
        width: calc(100% - 10rem);
        margin: 0 5rem;
    }
`;
const ButtonGroup = styled.div`
    background: ${white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: calc(100% - 2rem);
    min-width: 30rem;
    max-width: ${maxRowWidth};
    @media(${tabletMin}){
        margin-left: auto;
    }
`;
const Cancel = styled.button`
    margin-top: ${margin};
    font-size: ${CSSCommon.fontSize.h5};
    font-weight: ${CSSCommon.fontWeight.medium};
    background: ${white};
    color: ${black};
    border: none;
    cursor: pointer;
    transition: all ${CSSCommon.transition} ease-in-out;
    &:hover{
        text-decoration: underline;
    }
    ${props => props.scrollTop && css`
        height: 0;
        margin-top: 0;
        color: transparent;
    `}
`;
const ButtonComponent = (props) => {
    const {appState, setAppState} = props;

    const alterState = (type, data) => {
        setAppState((prevState) => ({
            [type]: data
        }));
    };

    const HttpRequest = (type, newMonth) => {
        setAppState({}, {type: type, payload: newMonth});
    };

    const onClick = () => {
        window.location = window.location.origin;
    };

    const setActiveStage = (stage) => {
        //Stage =1 for forwards =2 for backwards
        const newActiveStage = JSON.parse(JSON.stringify(appState.activeStage));
        if(appState.activeStage['stage' + stage]){
            newActiveStage.stage1 = !newActiveStage.stage1;
            newActiveStage.stage2 = !newActiveStage.stage2;
        } else if (appState.activeStage['stage' + (stage + 1)]){
            newActiveStage.stage2 = !newActiveStage.stage2;
            newActiveStage.stage3 = !newActiveStage.stage3;
        } else if (appState.activeStage['stage' + (stage + 2)]){
            newActiveStage.stage3 = !newActiveStage.stage3;
            newActiveStage.stage4 = !newActiveStage.stage4;
        }
        alterState('activeStage', newActiveStage);
    };

    const stageSpecificRequests = () => {
        if (appState.activeStage.stage1) {
            if(appState.isUnchangedRebooking || (appState.booking.status === 'PRELIMINARY' && !appState.bookingHasChanged)){
                setActiveStage(1);
            } else {
                HttpRequest('createBookingAndGetShops', null);
            }
            return true
        } else if (appState.activeStage.stage2) {
            setActiveStage(1);
            return true
        } else if (appState.activeStage.stage3) {
            if(appState.contactsIsValid && appState.termsIsValid){
                HttpRequest('confirmBooking', null);
                //newlyMadeBooking has to do with rebookability. Ensures the "new booking" button toggles correctly.
                //Rebooking should only be visible if the customer comes from either mail link or via bookingNr. Not on
                //the initial confirmation on a new booking.
                props.setAppState({newlyMadeBooking: true});
                return true
            } else {
                if(!appState.termsIsValid) {
                    props.setAppState({termsError: true});
                } else if(!appState.contactsIsValid) {
                    props.setAppState({validateContacts: true});
                    document.getElementsByTagName('form')[0][3].scrollIntoView({behavior: "smooth", block: "center"});    
                }
            }
        } else if (appState.activeStage.stage4) {
            return true
        }
        return false;
    };

    const hasChoosenProduct = vehicleType => {
        let hasChoosenProduct = false;
        if(appState[vehicleType].kind){
            appState[vehicleType].products.forEach((product) => {
                if(product.recommended)
                    hasChoosenProduct = true;
            })
        } else if (vehicleType === 'vehicleSecondary' ){
            hasChoosenProduct = true;
        }
        return hasChoosenProduct
    };
    const isDisabled = () => {
        return !((appState.activeStage.stage1 && appState.vehicleMain.registrationNumber
            && hasChoosenProduct('vehicleMain') && hasChoosenProduct('vehicleSecondary'))
            || (appState.activeStage.stage2 && appState.choosenTimeslot.interval)
            || (appState.activeStage.stage3))// && appState.contactsIsValid))
    };
    const renderBackButton = () => {
        return(
            <Button onClick={setActiveStage.bind(this, 2)}>Tillbaka</Button>
        )
    };

    const buttonText = () => {
        if(appState.activeStage.stage3 && appState.payWithKlarna){
            return 'Boka & betala'
        } else if(appState.activeStage.stage3 && appState.bookingIncludeInquiry){
            return 'Skicka förfrågan'
        } else if(appState.activeStage.stage3) {
            return 'Boka'
        } else {
            return 'Gå vidare'
        }
    };

    const backButton = !appState.activeStage.stage1 ?  renderBackButton() : null;
    return (
        <ButtonContainer>
            <ButtonColumn>
                <ButtonGroup>
                    {backButton}
                    <Button disabled={isDisabled()} next onlyNext={appState.activeStage.stage1} onClick={stageSpecificRequests}>{buttonText()}</Button>
                    <Cancel scrollTop={appState.headerHeight} onClick={onClick}>Avbryt bokning</Cancel>
                </ButtonGroup>
            </ButtonColumn>
        </ButtonContainer>
    );
};

export default ButtonComponent
