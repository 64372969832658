import React, { Component } from 'react';
import styled, {css} from 'styled-components';
import {SVG} from "../../svg/SVG.js"
import {CSSCommon} from "../../variables/CSSVariables.js";
import {TextH5} from "../../variables/StyledComponents.js";

const error         = CSSCommon.color.error;
const black         = CSSCommon.color.black;
const green         = CSSCommon.color.green;
const yellow        = CSSCommon.color.yellow;
const tabletMin     = CSSCommon.mediaBreakpoints.tabletMin;
const hover         = CSSCommon.color.hover;
const mobileMax     = CSSCommon.mediaBreakpoints.mobileMax;

const ComponentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 30rem;
    max-width: 32.4rem;
    margin-bottom: 2rem;
    ${props => props.isMobile && css`
        @media(${tabletMin}){
            display: none;
        }
    `}
    ${props => !props.isMobile && css`
        @media(${mobileMax}){
            display: none;
        }
    `}
`;

const CheckboxItem = styled.button`
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    text-align: left;
    width: 100%;
    transition: 0.3s;
    margin-top: 0.4rem;
    margin-bottom:0.4rem;

    &:hover {
        background-color: ${(props) => (props.checked ? 'white' : hover)}; /* Light green background on hover if not checked */
        cursor: pointer;
    }

    ${(props) =>
    props.checked &&
    css`
        border-color: ${green};
        background-color: white;
    `}
    ${props => props.hideText && css`
        display: none;
    `}
        ${props => props.validationError && css`
        border: solid 0.2rem ${error};
    `}
`;

const SVGCheckmark = styled.div`
    position: relative;
    width: 2rem;  /* Adjust the size of the checkbox */
    height: 2rem;
    border: solid 0.1rem ${green};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    background-color: ${(props) => (props.checked ? green : 'transparent')};
    transition: 0.3s;

    & svg {
        width: 100%;
        height: 100%;
        fill: ${(props) => (props.checked ? yellow : 'transparent')};
    }
`;

const TermsLabel = styled.p`
    font-size: 14px;
    margin: 0;
    font-weight: ${CSSCommon.fontWeight.normal};
`;

const H5 = styled(TextH5)`
    align-self: center;
    line-height: 1.3;
    white-space: pre-wrap;
`;
const H5Link = styled.button`
    font-size: 13px;
    font-weight: ${CSSCommon.fontWeight.normal};
    color: ${black};
    color: ${green};
    line-height: 1.3;
    white-space: pre-wrap;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
    ${props => props.hidden && css`
        display: none;
    `}
`;

class ConsentTermsComponent extends Component {
    constructor(props) {
        super(props); 

        this.state = {
            acceptedGdpr: null,
            acceptedTerms: null,
            acceptedService: null
        };
    }

    openInNewTab = url => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    };

    toggleTerms = type => {
        this.props.setAppState({renderTerms: type});
    };

    termsOnClick = type => {
        const currDate = Date.now();
        
        switch (type) {
            case "GDPR":
                if(this.terms.consentGdpr === '') {
                    this.terms.consentGdpr = currDate;
                }else {
                    this.terms.consentGdpr = '';
                }
                this.setState({acceptedGdpr: !!this.terms.consentGdpr})

                break;
            case "TERMS":
                if(this.terms.consentTerms === '') {
                    this.terms.consentTerms = currDate;
                } else {
                    this.terms.consentTerms = '';
                }
                this.setState({acceptedTerms: !!this.terms.consentTerms})
                break;
            case "SERVICE":
                if(this.terms.consentService === '') {
                    this.terms.consentService = currDate
                } else {
                    this.terms.consentService = '';
                }
                this.setState({acceptedService: !!this.terms.consentService})
                break;
        }

        this.props.setAppState({terms: this.terms})
        this.props.setAppState({termsIsValid: this.validateTerms()}); 

    }; 
    
    validateTerms = () => {
        const terms = this.terms.consentTerms;
        const gdpr = this.terms.consentGdpr;
        const service = this.terms.consentService;
        const isKlarna = this.props.appState.payWithKlarna;
        
        if(isKlarna) {
            if(terms !== '' && gdpr !== '' && service !== ''){
                this.props.setAppState({termsIsValid: true}); 
                return true;            
            }
        } else {
            if(terms !== '' && gdpr !== ''){
                this.props.setAppState({termsIsValid: true}); 
                return true;            
            }
        }

        this.props.setAppState({termsIsValid: false});

        return false;
    };

    componentWillMount() {
        this.terms = JSON.parse(JSON.stringify(this.props.appState.terms));
        if(!this.terms) {
            this.terms = {
                consentGdpr: '',
                consentTerms: '',
                consentService: ''
            };
        } else {
            this.props.setAppState({
                terms: this.terms
            });
        }
    }

    componentDidMount() {
        this.props.setAppState({termsIsValid: false});
    }

    render() {
        return (
            <ComponentContainer isMobile={this.props.isMobile}>                       
                <H5Link 
                    onClick={this.toggleTerms.bind(this, 2)}>
                    Våra bokningsregler
                </H5Link>
                <h5>
                    Vi behöver dina uppgifter för att skicka bekräftelse och påminnelse innan besöket samt för att kunna
                    nå dig om ombokning behövs p.g.a. sjukdom eller annan oförutsägbar händelse. För att vi ska kunna
                    göra det behöver vi informera om vår hantering av personuppgifter (GDPR) och att du godkänner
                    den samt våra allmänna villkor.
                </h5>
                
                <CheckboxItem 
                    type="button"
                    name='GDPR' 
                    onClick={this.termsOnClick.bind(this,'GDPR')}
                    validationError={this.props.appState.termsError && !this.state.acceptedGdpr}
                >
                    <SVGCheckmark checked={this.state.acceptedGdpr}>
                        {SVG.checkmark}
                    </SVGCheckmark>
                    <TermsLabel>Jag godkänner</TermsLabel>
                    <H5Link 
                        onClick={this.openInNewTab.bind(this,"https://dekra-bilbesiktning.se/om-oss/anvandarvillkor/")}
                    > DEKRAs hantering av GDPR
                    </H5Link>
                    <H5> *</H5>
                </CheckboxItem>

                <CheckboxItem 
                    type="button"
                    name='TERMS' 
                    onClick={this.termsOnClick.bind(this,'TERMS')}
                    validationError={this.props.appState.termsError && !this.state.acceptedTerms}
                >
                    <SVGCheckmark checked={this.state.acceptedTerms}>
                        {SVG.checkmark}
                    </SVGCheckmark>
                    <TermsLabel>Jag godkänner</TermsLabel> 
                    <H5Link 
                        onClick={this.openInNewTab.bind(this,"https://dekra-bilbesiktning.se/om-oss/allmanna-villkor-oversikt/")}
                    > DEKRAs allmänna villkor
                    </H5Link>
                    <H5> *</H5>
                </CheckboxItem>


                <h5 hidden={!this.props.appState.payWithKlarna}>
                    Enligt distansköpslagen har du rätt att ångra ditt köp utan anledning inom 14 dagar från att din
                    bokning har bekräftats. För att utöva din rätt att ångra ditt köp måste du informera oss via
                    <H5Link onClick={this.openInNewTab.bind(this,"https://dekra-bilbesiktning.se/kundservice/")}> webbformulär </H5Link>eller telefon 0771-441 441. Du
                    kan även använda<H5Link onClick={this.openInNewTab.bind(this,"https://dekra-bilbesiktning.se/om-oss/jag-angrar-mitt-kop/")}> ångerrättsblankett </H5Link>för att utöva din rätt.
                    
                    Observera att du inte kan ångra ditt köp när din bokade tjänst påbörjats, alternativt vid den tidpunkt
                    tjänsten skulle påbörjats.
                </h5>

                <CheckboxItem 
                    type="button"
                    name='SERVICE' 
                    onClick={this.termsOnClick.bind(this,'SERVICE')}
                    hideText={!this.props.appState.payWithKlarna}
                    validationError={this.props.appState.termsError && !this.state.acceptedService}
                >
                    <SVGCheckmark checked={this.state.acceptedService}>
                        {SVG.checkmark}
                    </SVGCheckmark>
                    <TermsLabel>Jag godkänner att tjänsten genomförs.</TermsLabel>
                    <H5> *</H5>
                </CheckboxItem>                    
                    
            </ComponentContainer>
        );
    }
}

export default ConsentTermsComponent